<template>
  <div>
    <AppHeader title="密码找回"/>
    <Panel class="tabs">
      <template slot="title">
        <div :class="`tab${tabIndex===0?' active':''}`" @click="switchType(0)">手机验证</div>
        <div :class="`tab${tabIndex===1?' active':''}`" @click="switchType(1)">邮箱验证</div>
      </template>
      <div slot="content">
        <div v-if="tabIndex === 0 && !reset">
          <div class="form-group mt20">
            <div class="form-input-item">

              <input class="form-control" v-model="phone" placeholder="请填写手机号">
            </div>
          </div>
          <div class="form-group">
            <div class="form-input-item">
              <div class="flex-dir-row">
                <div class="flex-item-auto">

                  <input class="form-control" v-model="captcha" placeholder="请填写验证码">
                </div>
                <div class="captcha-code">
                  <img :src="imgBase64" @click="changeCaptcha">
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-input-item">
              <div class="flex-dir-row">
                <div class="flex-item-auto">

                  <input class="form-control" v-model="mobileCode" placeholder="请填写验证码">
                </div>
                <div class="captcha-code">
                  <a class="home-sms-btn sso-2" style="height: auto;right: 0.15rem;font-size: 16px"  v-if="smsCounter==0" @click="startCounter(1)">获取验证码</a>
                  <div class="home-sms-counter-remark" v-else>{{smsCounter}}秒再次发送</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="btn dark-blue login-btn" @click="doReset()">下一步</div>
          </div>
        </div>
        <div v-if="tabIndex === 1">
          <div class="form-group mt20">
            <div class="form-input-item">

              <input class="form-control" v-model="email" placeholder="请填写邮箱">
            </div>
          </div>
          <div class="form-group">
            <div class="form-input-item">
              <div class="flex-dir-row">
                <div class="flex-item-auto">

                  <input class="form-control" v-model="captcha" placeholder="请填写验证码">
                </div>
                <div class="captcha-code">
                  <img :src="imgBase64" @click="changeCaptcha">
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="btn dark-blue login-btn" @click="doResetEmail()">发送重置邮件</div>
          </div>
        </div>
        <div>
          <div class="form-group mt20" v-if="reset">
            <div class="form-input-item">
              <input class="form-control" style="margin: 10px 0" v-model="pwd1" type="password" placeholder="请填写密码">
              <input class="form-control" v-model="pwd2" type="password" placeholder="请填写密码">
            </div>
            <div>
              <div class="btn dark-blue login-btn" @click="save()">提交</div>
            </div>
          </div>


        </div>
      </div>
    </Panel>
  </div>
</template>
<style lang="scss" scoped>
.identity-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.identity-login-content-right {
  margin-left: 15px;
  text-align: center;
}

.identity-login .icon-license {
  font-size: 50px !important;
}

.identity-title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 5px;
}

.identity-sub-title {
  font-size: 15px;
  color: RGBA(174, 174, 174, 1.00);
  margin-top: 5px;
}

.login-panel {
  .panel-title {
    font-size: 24px;
    font-weight: 700;
    color: #1f1f1f;
    margin-bottom: 5px;
  }

  .content {
    margin: 45px 28px;
  }

  .form-control {
    border-radius: 6px;
    border: 2px solid #F5F5F5;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    padding: 15px 15px 15px 45px;
  }
}

.login-title {
  color: #666;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.form-group {
  margin-bottom: 16px;
}

.form-control::placeholder {
  color: #97a4af;
  opacity: 1;
}

.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #212529;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #eeeeff;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
}

.form-input-item {
  position: relative;
}

.login-from-title {
  font-size: 22px;
  font-weight: 500;
}

.captcha-code {
  width: 120px;
  margin-left: 10px;
}

.login-bg {
  margin: 0;
  position: absolute;
  top: 0;
  height: 100% !important;
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.login-head {
  background: url("../assets/images/login-head.png");
  background-size: cover;
  height: 221px;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-holder {
  width: 92px;
  height: 92px;
  background: rgba(255, 255, 255, .3);
  border-radius: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-holder-inner {
  width: 84px;
  height: 84px;
  border-radius: 42px;
  overflow: hidden;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90%;
}

.logo-project {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
  color: #FFFFFF;
}

.login-mobile, .login-pwd, .login-captcha {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  position: absolute;
  left: 16px;
  top: 17px;
}

.login-mobile {
  background-image: url("../assets/images/login-mobile.png");
}

.login-pwd {
  background-image: url("../assets/images/login-pwd.png");
}

.login-captcha {
  background-image: url("../assets/images/login-captcha.png");
}

.login-btn {
  background: #1784A9 !important;
  margin-top: 30px;
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {MessageBox, Toast} from 'mint-ui';
export default{
  components: {
    AppHeader,
    Panel
  },
  data() {
    return {
      tabIndex: 0,
      phone: '',
      email: '',
      imgBase64: '',
      captcha: '',
      smsCounter:0,
      mobileCode: '',
      pwd1: '',
      pwd2: '',
      reset: false
    }
  },
  methods: {
    doResetEmail(){
      let that = this
      let data = new FormData()
      data.append('forgot', this.email)
      data.append('captchaCode', this.captcha)
      this.$http.post("/portal/account/forgot?mobile=false",data).then(res =>{
        if (res.success){
          Toast('重置邮件已发送，请查收')
          setTimeout(function (){
            that.$router.push({path: '/'})
          },1000)
        }
      })
    },
    save(){
      //判断两次密码是否相同
      if (this.pwd1 !== this.pwd2) {
        Toast('两次密码不一致')
        return
      }
      let that = this
      //验证手机和验证码
      let data = new FormData()
      data.append('mobile', this.phone)
      data.append('mobileValidCode', this.mobileCode)
      data.append('pwd', this.pwd1)
      data.append('pwd1', this.pwd2)
      this.$http.post('/portal/account/resetPwdByMobile', data).then(res =>{
        if (res.success){
          Toast('密码重置成功')
          setTimeout(function (){
            that.$router.push({path: '/'})
          },1000)
        }

      })
    },
    doReset(){
      let that = this
    //验证手机和验证码
      let data = new FormData()
      data.append('mobile', this.phone)
      data.append('mobileValidCode', this.mobileCode)
      this.$http.post('/portal/account/checkSmsCode', data).then(res =>{
        if (res.success){
          that.reset = true
        }

      })
    },
    loopCounterUntil(testFun, callback, timeout) {
      const that = this;
      if (testFun()) {
        setTimeout(function () {
          callback();
          that.loopCounterUntil(testFun, callback, timeout)
        }, timeout)
      }
    },
    startCounter(type){
      var that = this
      // 1手机 2 邮箱
      if (type===1) {

        let data = new FormData()
        data.append('mobile', that.phone)
        data.append('captchaCode', that.captcha)
        if(that.captcha===''){
          Toast('请输入图形验证码')
          return;
        }
        if(that.phone===''){
          Toast('请输入手机号码')
          return;
        }else {
          var reg = /^1[3-9]\d{9}$/;
          if(!reg.test(that.phone)){
            Toast('请输入正确的手机号码')
            return;
          }
        }
        this.$http.post('/portal/account/sendSms', data).then(res =>{
          if (!res.success) {
            Toast(res.msg);
          } else {
            that.smsCounter = 60;
            that.loopCounterUntil(function () {
              return that.smsCounter > 0;
            }, function () {
              that.smsCounter = that.smsCounter - 1;
            }, 1000);
          }
        })
      }
    },
    switchType(tabIndex){
      this.tabIndex = tabIndex
    },
    changeCaptcha() {
      const that = this
      this.$http.get(`/common/captcha?v=${Math.random()}`, {
        responseType: 'arraybuffer'
      }).then(response => new Buffer(response, 'binary').toString('base64'))
          .then(data => {
            that.imgBase64 = `data:image/png;base64,${data}`
          });
    },
},
  mounted() {
    this.changeCaptcha()
  }
}
</script>
